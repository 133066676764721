<template>
  <div class="user-box">
    <div class="user-manage">
      <el-row style="height:100%">
        <el-col class="top" style="padding:20px;margin-right:20px;width:300px;background:#fff;height:100%">
          <div class="left">部门管理</div>
          <el-input v-model="filterText" placeholder="输入关键字进行过滤" />
          <div class="dev-type-main-left organization_configuration">

            <div v-show="showRightMenu">
              <ul id="menu" ref="rightmenuref" class="right-menu">
                <li class="menu-item" @click="addDevHandle(level)">新增下级区县部门</li>
              </ul>
            </div>

            <div v-show="showRightMenu1">
              <ul id="menu1" ref="rightmenuref1" class="right-menu1">
                <li v-show="level != '1' && state === '1'" class="menu-item" @click="addDevHandle(level)">新增下级学校</li>
                <li v-show="level != '1' && state === '1'" class="menu-item" @click="editDevHandle(level)">编辑区县部门</li>
                <li v-show="level != '1' && state === '1'" class="menu-item" @click="uploadDepartment()">导入学校</li>
                <li v-show="level != '1' && state === '1'" class="menu-item" @click="end">禁用部门</li>
                <li v-show="level != '1' && state === '2'" class="menu-item" @click="start">启用部门</li>
              </ul>
            </div>

            <div v-show="showRightMenu2">
              <ul id="menu2" ref="rightmenuref2" class="right-menu2">
                <li v-show="state === '1'" class="menu-item" @click="editDevHandle(level)">编辑学校</li>
                <li v-show="state === '1'" class="menu-item" @click="uploadUser()">导入用户</li>
                <li v-show="state === '1'" class="menu-item" @click="end">禁用学校</li>
                <li v-show="state === '2'" class="menu-item" @click="start">启用学校</li>
              </ul>
            </div>

            <el-tree
              ref="tree"
              highlight-current
              class="filter-tree"
              style="background: transparent;"
              :data="dataList"
              :props="defaultProps"
              default-expand-all
              :filter-node-method="filterNode"
              :default-expanded-keys="TreeArr"
              check-on-click-node
              :expand-on-click-node="false"
          
              @node-click="selTree"
            >
              <span slot-scope="{ node }">
                <span style="font-size: 15px;">
                  <i v-if="node.data.state == '1'" class="el-icon-bank-card" />
                  <i v-if="node.data.state != '1'" class="el-icon-close" />
                  <span style="margin-left:5px;">{{ node.label }}</span>
                </span>
              </span>
            </el-tree>
          </div>
        </el-col>

        <el-col id="app-contain" class="top" style="padding-left:20px;width:calc(100% - 320px);background:#fff;height:100%">
          <div id="filter-container" class="left" style="padding:10px;margin-bottom:0">用户列表
            <span class="left" style="font-size:15px;">当前选择部门：<span style="color: #409EFF;">{{ chickDepartment.name }}</span>

            </span>

            <!-- <el-button type="primary" class="add-input" style="float:right;margin:0 0 0 10px" size="mini" @click="universityTemplate()">下载学校模版</el-button> -->
            <el-button type="primary" class="add-input" style="float:right;margin:0 0 0 10px" size="mini" @click="userWorkTemplate(false)">下载学校用户模版</el-button>
            <el-button type="primary" class="add-input" style="float:right;margin:0 0 0 10px" size="mini" @click="setUser(false)">新增用户</el-button>
            <el-button type="primary" v-if="this.chickDepartment.level==3" class="add-input" style="float:right;margin:0 0 0 10px" size="mini" @click="uploadUser()">导入用户</el-button>

          </div>

          <div class="table-box">
            <el-table ref="refsTable" :data="tableData" :height="tabheight" style="width: 100%">
              <el-table-column label="序号" type="index" align="center" width="50x" />
              <el-table-column prop="userName" label="用户名" />
              <el-table-column prop="nickName" label="姓名" />
              <el-table-column label="角色">
                <template slot-scope="scope">
                  <span>{{ getRoleName(scope.row.roleId) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="name" align="center" label="状态">
                <template slot-scope="scope">
                  <span v-if="scope.row.userState==='1'">启用</span>
                  <span v-if="scope.row.userState==='2'">禁用</span>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" />
              <el-table-column prop="edit" label="操作" width="240">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.userState==='1'"
                    size="small"
                    type="primary"
                    @click="setUser(scope.row)"
                  >编辑</el-button>
                  <el-button
                    v-if="scope.row.userState==='1'"
                    size="small"
                    type="danger"
                    @click="handleChangeState(scope)"
                  >禁用</el-button>
                  <el-button
                    v-if="scope.row.userState==='2'"
                    size="small"
                    type="primary"
                    @click="handleChangeState(scope)"
                  >启用</el-button>
                  <el-button
                    v-if="scope.row.userState==='1'"
                    size="small"
                    type="primary"
                    @click="rePassword(scope)"
                  >重置密码</el-button>
                </template>
              </el-table-column>
            </el-table>

            <div id="pagination" class="pagination-info">
              <div class="left">
                第 {{ this.queryPage.page }} 页（共{{ Math.ceil(total / this.queryPage.limit) }}页），显示{{
                  (this.queryPage.page - 1) * this.queryPage.limit + 1
                }}至{{
                  this.queryPage.page * this.queryPage.limit >= total ? total : this.queryPage.page * this.queryPage.limit
                }}项结果，共{{ total }}项
              </div>
              <div class="right">
                <el-pagination
                  :page-sizes="[5, 10, 15, 20]"
                  :page-size="this.queryPage.limit"
                  layout="total, sizes, prev, pager, next, jumper"
                  style="margin-bottom: 28px;"
                  :total="total"
                  @size-change="handleSizeChange"
                  @current-change="paginationHandle"
                />
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-dialog :close-on-click-modal="false"
        :title="title"
        width="45%"
        :visible.sync="dialogFormVisible"
        :modal-append-to-body="false"
        @close="closeViewUser"
      >
        <el-form :label-position="'left'" label-width="100px" :model="form">
          <el-form-item>
            <div slot="label">部门<font color="red">*</font></div>
            <el-input v-model="form.departmentName" readonly />
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="用户名：">
                <div slot="label">用户名<font color="red">*</font></div>
                <el-input v-model="form.userName" placeholder="请输入用户名" onkeyup="value=value.replace(/[^\w\.\/]/ig,'')" @blur="form.userName = $event.target.value"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <div slot="label">真实姓名<font color="red">*</font></div>
                <el-input v-model="form.nickName" placeholder="请输入真实姓名" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item>
                <div slot="label">密码<font color="red">*</font></div>
                <el-input v-model="form.password" type="password" :placeholder="passwordph" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <div slot="label">确认密码<font color="red">*</font></div>
                <el-input v-model="form.password2" type="password" :placeholder="passwordph" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item>
                <div slot="label">角色<font color="red">*</font></div>
                <el-select v-model="form.roleId" placeholder="请选择角色" style="width:100%;">
                  <el-option v-for="item in roles" :key="item.id" :label="item.name" :value="item.id" style="width:100%;" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <div slot="label">联系电话<font color="red">*</font></div>
                <el-input v-model="form.phone" placeholder="请输入联系电话" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div style="margin-top:10px;text-align:center;">
          <el-button type="primary" @click="addUserHandle">保 存</el-button>
          <el-button type="danger" @click="dialogFormVisible = false">取 消</el-button>
        </div>
      </el-dialog>

      <el-dialog :close-on-click-modal="false"
        :title="title1"
        :visible.sync="departmentFormVisible"
        width="60%"
        :modal-append-to-body="false"
        @close="closeView"
      >
        <el-form :label-position="'left'" label-width="110px" :model="departmentForm">
          <el-row :gutter="22">
            <el-col :span="22">
              <el-form-item>
                <div slot="label">上级部门<font color="red">*</font></div>
                <el-input v-model="departmentForm.parentIdText" readonly />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="22">
            <el-col :span="11">
              <el-form-item>
                <div slot="label">区县代码<font color="red">*</font></div>
                <el-input v-model="departmentForm.code" placeholder="请输入区县代码" />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item>
                <div slot="label">区县名称<font color="red">*</font></div>
                <el-input v-model="departmentForm.name" placeholder="请输入区县名称" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="22">
            <el-col :span="22">
              <el-form-item>
                <div slot="label">统一社会代码<font color="red">*</font></div>
                <el-input v-model="departmentForm.usei" placeholder="请输入统一社会代码" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="22">
            <el-col :span="22">
              <el-form-item>
                <div slot="label">地址<font color="red">*</font></div>
                <el-input v-model="departmentForm.address" placeholder="请输入地址" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="22">
            <el-col :span="11">
              <el-form-item>
                <div slot="label">联系人<font color="red">*</font></div>
                <el-input v-model="departmentForm.contacts" placeholder="请输入联系人" />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item>
                <div slot="label">电话<font color="red">*</font></div>
                <el-input v-model="departmentForm.phone" placeholder="请输入电话"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="22">
            <el-col :span="22">
              <el-form-item>
                <div slot="label">备注</div>
                <el-input v-model="departmentForm.remark" type="textarea" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div style="margin-top:10px;text-align:center;">
          <el-button type="primary" @click="editDepartment('area')">保 存</el-button>
          <el-button type="danger" @click="departmentFormVisible = false">取 消</el-button>
        </div>
      </el-dialog>

      <el-dialog :close-on-click-modal="false"
        :title="title1"
        :visible.sync="departmentSchoolFormVisible"
        :modal-append-to-body="false"
        @close="closeView"
      >
        <el-form :label-position="'left'" label-width="80px" :model="departmentForm">
          <el-form-item>
            <div slot="label">上级部门<font color="red">*</font></div>
            <el-input v-model="departmentForm.parentIdText" disabled />
          </el-form-item>
          <el-form-item>
            <div slot="label">学校代码<font color="red">*</font></div>
            <el-input v-model="departmentForm.usei" placeholder="请输入学校代码"/>
          </el-form-item>
          <el-form-item>
            <div slot="label">学校名称<font color="red">*</font></div>
            <el-input v-model="departmentForm.name" placeholder="请输入学校名称"/>
          </el-form-item>
          <el-form-item>
            <div slot="label">学校类型<font color="red">*</font></div>
            <el-select
              v-model="departmentForm.txpeofcamous"
              class="filter-item"
              placeholder="请选择学校类型"
              style="width: 100%"
            >
              <el-option
                v-for="item in schoolTypeList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <div slot="label">备注</div>
            <el-input v-model="departmentForm.remark" type="textarea" />
          </el-form-item>
        </el-form>

        <div style="margin-top:10px;text-align:center;">
          <el-button type="primary" @click="editDepartment('school')">保 存</el-button>
          <el-button type="danger" @click="departmentSchoolFormVisible = false">取 消</el-button>
        </div>
      </el-dialog>

      <el-dialog :close-on-click-modal="false" title="导入学校" width="30%" :visible.sync="dialogVisibleReport"  @close="closeUpload">
        <el-upload
          ref="upload"
          style="text-align: center"
          class="upload-demo"
          :action="uploadPath"
          drag
          :headers="uploadHeaders"
          :limit="1"
          :before-upload="beforeAvatarUpload"
          :on-success="uploadSuccess"
          name="file"
        >
          <i class="el-icon-upload" />
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div slot="tip" class="el-upload__tip">只能上传Excel文件</div>
        </el-upload>
        <div style="margin-top:10px;text-align:center;">
          <el-button type="primary" @click="reportDepartment()">保 存</el-button>
          <el-button type="danger" @click="dialogVisibleReport = false">取 消</el-button>
        </div>
      </el-dialog>

      <el-dialog :close-on-click-modal="false" title="导入用户" width="30%" :visible.sync="dialogVisibleReport1"  @close="closeUpload">
        <el-upload
          ref="upload"
          style="text-align: center"
          class="upload-demo"
          :action="uploadPath"
          drag
          :headers="uploadHeaders"
          :limit="1"
          :before-upload="beforeAvatarUpload"
          :on-success="uploadSuccess"
          name="file"
          :file-list="fileList"
        >
          <i class="el-icon-upload" />
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div slot="tip" class="el-upload__tip">只能上传Excel文件</div>
        </el-upload>
        <div style="margin-top:10px;text-align:center;">
          <el-button type="primary" @click="reportUser()">保 存</el-button>
          <el-button type="danger" @click="dialogVisibleReport1 = false">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import tool from '@/utils/tool'
import { getInitialLoading, getAgainLoading, saveData, start, end, getdeUsernum,
  getchirldnum, getparentName, universityTemplate, userWorkTemplate, reportDepartment, downloadWrongReport } from '@/api/organization'
import { getCityList as getUserList, saveData as saveUser, repStallsPasssWord, updateState, reportDepartmentUser } from '@/api/user'
import { getCityPartList, getRoleList } from '@/api/role'
import { getDictListByCode } from '@/api/dict'
import { Message, MessageBox } from 'element-ui'
export default {
  data() {
    return {
      tabheight: 0,
      roles: [], // 角色列表
      heights: 400,
      tableData: [],
      isAdd: true,
      departmentSchoolFormVisible: false,
      departmentFormVisible: false,
      dialogFormVisible: false,
      form: {
        id: '',
        departmentName: '',
        departmentId: '',
        username: '',
        nickName: '',
        password: '',
        password2: '',
        phone: null
      },
      fileList: [],
      departmentForm: {
        parentIdText: '',
        parentId: '',
        name: '',
        id: '',
        remark: '',
        path: '',
        state: '',
        code: '',
        address: '',
        usei: '',
        contacts: '',
        phone: '',
        txpeofcamous: null
      },
      total: 0,
      filterText: '',
      dataList: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      showRightMenu: false,
      showRightMenu1: false,
      showRightMenu2: false,
      selDepartment: {},
      chickDepartment: {},
      passwordph: '',
      title: '',
      title1: '',
      queryPage: {
        nickName: '',
        page: 1,
        limit: 10,
        id: ''
      },
      TreeArr: [],
      IdArr: [],
      level: '',
      Node: '',
      DATA: '',
      state: '',
      dataId: [],
      schoolTypeList: [],
      roleList: [],
      // 导入
      reportData: {
        departmentId: null,
        fileUrl: null
      },
      dialogVisibleReport: false,
      dialogVisibleReport1: false,
      uploadHeaders: {},
      uploadPath: ''
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  mounted() {
    this.$nextTick(function() {
      var filterHeight = document.getElementById('filter-container').offsetHeight
      var tableHeight = document.getElementById('app-contain').offsetHeight
      var pagination = document.getElementById('pagination').offsetHeight
      this.tabheight = tableHeight - filterHeight - pagination
      console.log(this.tabheight, filterHeight, tableHeight, pagination)
      window.onresize = () => {
        return (() => {
          var filterHeight = document.getElementById('filter-container').offsetHeight
          var tableHeight = document.getElementById('app-contain').offsetHeight
          var pagination = document.getElementById('pagination').offsetHeight
          this.tabheight = tableHeight - filterHeight - pagination
        })()
      }
    })
  },
  created() {
    this.getUploadHeader()
    this.getAllRoleList()
    this.getDictListByCode('TXPE_OF_CAMOUS')
    this.listDepartment()
  },

  methods: {
    // 关闭新增用户弹框
    closeViewUser() {
      this.getAllRoleList()
      this.listUser()
      this.form = {
        id: '',
        departmentName: '',
        departmentId: '',
        username: '',
        nickName: '',
        password: '',
        password2: '',
        phone: null
      }
    },
    // 关闭页面
    closeView() {
      this.departmentForm = {
        parentIdText: '',
        parentId: '',
        name: '',
        id: '',
        remark: '',
        path: '',
        state: '',
        code: '',
        address: '',
        usei: '',
        contacts: '',
        phone: '',
        txpeofcamous: null
      }
    },
    // 角色列表
    getAllRoleList() {
      getRoleList().then(response => {
        this.roleList = response.data
      })
    },
    // 保存导入
    reportUser() {
      if (!this.reportData.fileUrl) {
        this.$message.error('请先上传文件只有在进行保存')
        return
      }
      reportDepartmentUser(this.reportData).then(response => {
        console.log(response)
        if (!response.data) {
          this.$message.success('导入成功')
        } else {
          // 下载错误信息
          this.$message.error(response.msg)
          downloadWrongReport({ fileUrl: response.data })
        }
      }).catch(error => {
      })
    },
    // 保存导入
    reportDepartment() {
      if (!this.reportData.fileUrl) {
        this.$message.error('请先上传文件只有在进行保存')
        return
      }
      reportDepartment(this.reportData).then(response => {
        if (!response.data) {
          this.$message.success('导入成功')
        } else {
          this.$message.error(response.msg)
          downloadWrongReport({ fileUrl: response.data })
        }
      }).catch(error => {
      })
    },
    // 上传成功之后文件非空判断
    uploadSuccess(response, file, fileList) {
      if (response.code === 20000) {
        this.reportData.fileUrl = response.data
      } else {
        this.reportData.fileUrl = null
        this.$message.error(response.msg)
        return
      }
    },
    // 关闭导入
    closeUpload() {
      this.uploadPath = ''
      this.reportData = {
        departmentId: null,
        fileUrl: null
      }
      this.fileList = []
      this.listDepartment()
      this.searchManagerUsers()
    },
    // 导入用户
    uploadUser() {
      if(process.env.NODE_ENV === 'development'){
        this.uploadPath = process.env.VUE_APP_URL + '/ltcloud/department/uploadFile'
      }else{
        this.uploadPath = process.env.VUE_APP_BASE_API_PROD + '/ltcloud/department/uploadFile'
      }
      this.dialogVisibleReport1 = true
      this.reportData.departmentId = this.chickDepartment.id
    },
    // 导入学校数据
    uploadDepartment() {
      if(process.env.NODE_ENV === 'development'){
        this.uploadPath = process.env.VUE_APP_URL + '/ltcloud/department/uploadFile'
      }else{
        this.uploadPath = process.env.VUE_APP_BASE_API_PROD + '/ltcloud/department/uploadFile'
      }
      this.dialogVisibleReport = true
      this.reportData.departmentId = this.DATA.id
    },
    // 初始化请求头
    getUploadHeader() {
      this.uploadHeaders = {
        'X-TOKEN': tool.getCookie('X-Token')
      }
    },
    // 判断文件格式
    beforeAvatarUpload(file) {
      const fileName = file.name
      let flag = false
      // 取出上传文件的扩展名
      const index = fileName.lastIndexOf('.')
      const ext = fileName.substring(index + 1)
      // 循环比较
      if (ext === 'xlsx' || ext === 'xls') {
        flag = true
      }
      if (!flag) {
        this.$message.error('此格式文件不允许上传')
        return false
      }
      return true
    },
    // 下载用户模板
    userWorkTemplate() {
      userWorkTemplate()
    },
    // 下载学校模板
    universityTemplate() {
      universityTemplate()
    },
    // 字典
    getDictListByCode(code) {
      getDictListByCode(code).then(response => {
        if (code === 'TXPE_OF_CAMOUS') {
          this.schoolTypeList = response.data
        }
      })
    },
    onloadList() {
      this.TreeArr = []
      this.IdArr = []
      // 重新加载表格
      this.listDepartment()
    },
    getRoleName(code) {
      for (const role of this.roleList) {
        if (code === role.id) {
          return role.name
        }
      }
    },
    // 新增
    addDevHandle(level) {
      if (level == 1) {
        this.title1 = '新增区县部门'
        this.departmentFormVisible = true
        this.departmentForm.parentIdText = this.selDepartment.name
        this.departmentForm.parentId = this.selDepartment.id
      } else if (level == 2) {
        this.title1 = '新增学校'
        this.departmentSchoolFormVisible = true
        this.departmentForm.parentIdText = this.selDepartment.name
        this.departmentForm.parentId = this.selDepartment.id
      }
    },
    // 编辑
    editDevHandle(level) {
      if (level == 2) {
        this.title1 = '编辑区县部门'
        this.departmentFormVisible = true
        getparentName(this.selDepartment.id).then(response => {
          this.departmentForm = response.data
          this.departmentForm.parentIdText = this.selDepartment.parentIdText
          this.departmentForm.id = this.selDepartment.id
          this.departmentForm.parentId = this.selDepartment.parentId
        })
      } else if (level == 3) {
        this.title1 = '编辑学校'
        this.departmentSchoolFormVisible = true
        getparentName(this.selDepartment.id).then(response => {
          this.departmentForm = response.data
          this.departmentForm.parentIdText = this.selDepartment.parentIdText
          this.departmentForm.id = this.selDepartment.id
          this.departmentForm.parentId = this.selDepartment.parentId
        })
      }
    },
    rightClick(event, data, Node, obj) {
      this.DATA = data
      this.Node = Node
      this.showRightMenu = false // 先把模态框关死，目的是：第二次或者第n次右键鼠标的时候 它默认的是true
      this.showRightMenu1 = false // 先把模态框关死，目的是：第二次或者第n次右键鼠标的时候 它默认的是true
      this.showRightMenu2 = false // 先把模态框关死，目的是：第二次或者第n次右键鼠标的时候 它默认的是true
      this.state = data.state
      this.level = data.level
      this.departmentForm = {
        parentIdText: '',
        parentId: '',
        name: '',
        id: '',
        remark: ''
      }
      data.parentIdText = Node.parent.data.name
      this.selDepartment = JSON.parse(JSON.stringify(data))
      // 给整个document添加监听鼠标事件，点击任何位置执行closeRightMenu方法，及时将菜单关闭
      document.addEventListener('click', this.closeRightMenu)
      if (data.level == 1) {
        this.showRightMenu = true
        const menu = document.querySelector('#menu')
        menu.style.left = event.clientX - 250 + 'px'
        menu.style.top = event.clientY - 95 + 'px'
        if (data.id === 0) {
          this.$refs.rightmenuref.setAttribute('class', 'right-menu')
        } else {
          this.$refs.rightmenuref.setAttribute('class', 'right-menu')
        }
      } else if (data.level == 2) {
        this.showRightMenu1 = true
        const menu = document.querySelector('#menu1')
        menu.style.left = event.clientX - 250 + 'px'
        menu.style.top = event.clientY - 95 + 'px'
        if (data.id === 0) {
          this.$refs.rightmenuref1.setAttribute('class', 'right-menu1')
        } else {
          this.$refs.rightmenuref1.setAttribute('class', 'right-menu1')
        }
      } else {
        this.showRightMenu2 = true
        const menu = document.querySelector('#menu2')
        menu.style.left = event.clientX - 250 + 'px'
        menu.style.top = event.clientY - 95 + 'px'
        if (data.id === 0) {
          this.$refs.rightmenuref2.setAttribute('class', 'right-menu2')
        } else {
          this.$refs.rightmenuref2.setAttribute('class', 'right-menu2')
        }
      }
    },
    closeRightMenu() {
      this.showRightMenu = false
      this.showRightMenu1 = false
      this.showRightMenu2 = false
      // 及时关掉鼠标监听事件
      document.removeEventListener('click', this.closeRightMenu)
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    paginationHandle(index) {
      this.queryPage.page = index
      this.searchManagerUsers()
    },
    handleSizeChange(e) {
      this.queryPage.page = 1
      this.queryPage.limit = e
      this.searchManagerUsers()
    },
    searchManagerUsers() {
      this.listLoading = true
      getUserList(this.queryPage).then((response) => {
        const { data } = response
        this.total = parseInt(data.total)
        this.tableData = data.records
        this.listLoading = false
      })
    },
    // 角色列表
    setUser(e) {
      // 获取所有角色列表
      this.roles = []
      console.log(this.chickDepartment)
      getCityPartList({ departmentId: this.chickDepartment.id, flag: this.chickDepartment.level }).then(response => {
        this.roles = response.data
      }).catch(() => {
        this.$message.error('获取角色失败!请稍后重试')
      })

      // false 新增
      if (e) {
        this.title = '编辑用户'
        // 编辑
        this.form = {
          id: e.id,
          departmentName: this.chickDepartment.name,
          departmentId: this.chickDepartment.id,
          userName: e.userName,
          nickName: e.nickName,
          password: e.password,
          password2: e.password,
          roleId: e.roleId,
          phone: e.phone
        }
        this.passwordph = '(密码变更)'
        this.dialogFormVisible = true
      } else {
        this.title = '新增用户'
        if (typeof (this.chickDepartment.id) === 'undefined') {
          this.$message.error('请选择部门')
          return
        }
        // 新增
        this.isAdd = true
        this.form = {
          id: '',
          departmentName: this.chickDepartment.name,
          departmentId: this.chickDepartment.id,
          userName: '',
          nickName: '',
          password: '',
          password2: '',
          roleId: '',
          phone: null
        }
        this.passwordph = ''
        this.dialogFormVisible = true
      }
    },
    // 筛选出项目id
    filterProjectId(data) {
      const parr = []
      data.forEach((item) => {
        if (item.isChecked) {
          parr.push(item.id)
        }
      })
      this.form.projectIds = parr
    },
    addUserHandle() {
      if (!this.form.departmentName) {
        this.$message.error('请选择部门')
        return
      } else if (!this.form.userName) {
        this.$message.error('请输入用户名')
        return
      } else if (!this.form.nickName) {
        this.$message.error('请输入真实姓名')
        return
      } else if (!this.form.password) {
        this.$message.error('请输入密码')
        return
      } else if (!this.form.password2) {
        this.$message.error('请确认密码')
        return
      } else if (this.form.password !== this.form.password2) {
        this.$message.error('两次输入密码不一致')
        return
      } else if (!this.form.roleId) {
        this.$message.error('请选择角色')
        return
      } else if (!this.form.phone) {
        this.$message.error('请输入联系电话')
        return
      } else {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        saveUser(this.form).then((res) => {
          Message.success('新增成功')
          this.listUser()
          this.dialogFormVisible = false
          loading.close()
        }).catch(response => {
          loading.close()
        })
      }
    },
    setShebei(e) {
      if (!e.isOpen) return
      this.drawer = true
    },
    // 提交编辑部门信息
    editDepartment(flag) {
      if (flag === 'area') {
        if (!this.departmentForm.parentId) {
          this.$message.error('请选择上级部门')
          return
        } else if (!this.departmentForm.name) {
          this.$message.error('请输入区县名称')
          return
        } else if (!this.departmentForm.code) {
          this.$message.error('请输入区县代码')
          return
        } else if (!this.departmentForm.usei) {
          this.$message.error('请输入统一社会代码')
          return
        } else if (!this.departmentForm.address) {
          this.$message.error('请输入地址')
          return
        } else if (!this.departmentForm.contacts) {
          this.$message.error('请输入联系人')
          return
        } else if (!this.departmentForm.phone) {
          this.$message.error('请输入联系方式')
          return
        } else {
          // 调用接口完成操作
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          saveData(this.departmentForm).then((res) => {
            this.$message.success('操作成功')
            this.departmentFormVisible = false
            this.listDepartment()
            loading.close()
          }).catch(response => {
            loading.close()
          })
        }
      } else if (flag === 'school') {
        if (!this.departmentForm.parentId) {
          this.$message.error('请选择上级部门')
          return
        } else if (!this.departmentForm.name) {
          this.$message.error('请输入学校名称')
          return
        } else if (!this.departmentForm.usei) {
          this.$message.error('请输入学校代码')
          return
        } else if (!this.departmentForm.txpeofcamous) {
          this.$message.error('请选择学校类型')
          return
        } else {
          // 调用接口完成操作
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          saveData(this.departmentForm).then((res) => {
            Message.success('操作成功')
            this.departmentSchoolFormVisible = false
            this.listDepartment()
            loading.close()
          }).catch(response => {
            loading.close()
          })
        }
      }
    },
    listDepartment() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      getInitialLoading().then((response) => {
        this.dataList = response.data
        loading.close()
      }).catch(Response => {
        loading.close()
      })
    },
    // 节点过滤
    selTree(data, node, obj) {
      this.showRightMenu = false
      this.showRightMenu1 = false
      this.showRightMenu2 = false
      this.chickDepartment = JSON.parse(JSON.stringify(data))
      // getAgainLoading({ parentId: data.id }).then(response => {
      //   if (!data.children) {
      //     this.$set(data, 'children', [])
      //     response.data.forEach(newChild => {
      //       data.children.push(newChild)
      //     })
      //     this.IdArr.push(data.id)
      //     this.dataId = data.id
      //   } else {
      //     data.children = response.data
      //   }
      //   this.listLoading = false
      // }).catch(response => {
      //   this.listLoading = false
      // })
      this.listUser()
    },
    listUser() {
      this.queryPage.departmentId = this.chickDepartment.id
      getUserList(this.queryPage).then((response) => {
        const { data } = response
        this.total = parseInt(data.total)
        this.tableData = data.records
        this.listLoading = false
      })
    },
    rePassword(scope) {
      MessageBox.confirm('确定重置密码为Aa123456', '确定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'qx',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        repStallsPasssWord({ userId: scope.row.id }).then(response => {
          Message({
            message: '操作成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          this.listUser()
        }).catch(response => {
          loading.close()
        })
      })
    },
    handleChangeState(scope) {
      if (scope.row.userState === '1') { // 禁用
        MessageBox.confirm('确认禁用', '确定', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          updateState({ userId: scope.row.id, state: '2' }).then(response => {
            Message({
              message: '禁用成功',
              type: 'success',
              duration: 5 * 1000
            })
            // 重新加载表格
            this.listUser()
          })
        })
      } else if (scope.row.userState === '2') { // 启用
        MessageBox.confirm('确认启用', '确定', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          updateState({ userId: scope.row.id, state: '1' }).then(response => {
            Message({
              message: '启用成功',
              type: 'success',
              duration: 5 * 1000
            })
            // 重新加载表格
            this.listUser()
          })
        })
      }
    },
    start() {
      getparentName(this.DATA.parentId).then(response => {
        var { data } = response
        if (data.state === '2') {
          Message({
            message: '上级部门已禁用',
            type: 'error'
          })
        } else {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.1)'
          })
          start(this.DATA.id).then(response => {
            Message({
              message: '启用成功',
              type: 'success'
            })
            this.onloadList()
            loading.close()
          }).catch(response => {
            loading.close()
          })
        }
      }).catch(response => {
        return false
      })
    },
    end() {
      MessageBox.confirm('是否禁用', '禁用部门', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.Node.level === 2) {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.1)'
          })
          getchirldnum(this.DATA.id).then(response => {
            var { data } = response
            var count = parseInt(data)
            if (count !== 0) {
              Message({
                type: 'error',
                message: '该部门的子部门在使用',
                duration: 5 * 1000
              }
              )
            } else {
              var countUser
              getdeUsernum(this.DATA.id).then(response => {
                var { data } = response
                countUser = parseInt(data)
                if (count === 0 && countUser === 0) {
                  end(this.DATA.id).then(response => {
                    Message({
                      type: 'success',
                      message: '禁用成功!',
                      duration: 5 * 1000
                    })
                    this.onloadList()
                  }).catch(response => {
                    return false
                  })
                } else {
                  Message({
                    type: 'error',
                    message: '	该部门下有启用员工,无法禁用',
                    duration: 5 * 1000
                  }
                  )
                }
              }).catch(response => {
                return false
              })
            }

            loading.close()
          }).catch(response => {
            loading.close()
            return false
          })
        } else {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.1)'
          })
          // 禁用用户
          getdeUsernum(this.DATA.id).then(response => {
            var { data } = response
            var count = parseInt(data)
            if (count === 0) {
              end(this.DATA.id).then(response => {
                Message({
                  type: 'success',
                  message: '禁用成功!',
                  duration: 5 * 1000
                })
                this.onloadList()
              }).catch(response => {
                return false
              })
            } else {
              Message({
                type: 'error',
                message: '	该部门下有启用员工,无法禁用',
                duration: 5 * 1000
              })
            }
            loading.close()
            this.onloadList()
          }).catch(response => {
            loading.close()
            return false
          })
        }
      }).catch(() => {
        Message({
          type: 'info',
          message: '已取消禁用',
          duration: 5 * 1000
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user-box {
  height:calc(100% - 20px);
  width:calc(100% - 20px);
  padding: 20px;
}
.form-radio-box {
  margin-top: 20px;
  .el-form-item-20 {
    margin-bottom: 20px;
  }
}
.user-manage {
  height:100%;
  border-radius: 7px;
  .top {
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2000;
    .left {
      font-size: 18px;
      font-weight: 600;
      margin-bottom:28px
    }
  }
  .table-box {
    text-align: center;
    /deep/.el-table {
      background: rgba(0, 0, 0, 0);
      /deep/th,
      tr {
        background: rgba(0, 0, 0, 0);
      }
      /deep/th {
        > .cell {
          color: #152139;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
        }
      }
      /deep/tr {
        td {
          font-size: 14px;
          color: #152139;
          text-align: center;
        }
      }
      .btn-out-box {
        display: flex;
        justify-content: space-around;
      }
      .btn-box {
        width: 24px;
        height: 24px;
        background: rgba(165, 165, 165, 0.1);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .iconfont {
          font-size: 12px;
          color: rgba(195, 195, 195, 1);
        }
        &.color1.active {
          background: rgba(20, 96, 224, 0.1);
          .iconfont {
            color: rgba(20, 96, 224, 1);
          }
        }
        &.color2.active {
          background: rgba(255, 41, 41, 0.1);
          .iconfont {
            color: rgba(255, 41, 41, 1);
          }
        }
        &.color3.active {
          background: rgba(253, 151, 101, 0.1);
          .iconfont {
            color: rgba(253, 151, 101, 1);
          }
        }
      }
    }
  }
  .pagination-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    .left {
      font-size: 12px;
      color: rgba(137, 146, 159, 1);
    }
  }
  /deep/.el-dialog {
    width: 35%;
    .el-dialog__title {
      font-weight: 600;
    }
  }
  .dev-type-main-left {
    overflow: auto;
    height: 90%;
    .right-menu {
      z-index: 999;
      position: absolute;
      width: 140px;
      position: absolute;
      border-radius: 5px;
      border: 1px solid #ccc;
      background-color: white;
      .menu-item {
        line-height: 30px;
        padding-left: 10px;
        text-align: left;
        font-size: 14px;
        color: #606266;
      }
      li:hover {
        background-color: #edf6ff;
        cursor: pointer;
        color: #606266;
      }
    }
    .right-menu1 {
      z-index: 999;
      position: absolute;
      width: 140px;
      position: absolute;
      border-radius: 5px;
      border: 1px solid #ccc;
      background-color: white;
      .menu-item {
        line-height: 30px;
        text-align: left;
        padding-left: 10px;
        font-size: 14px;
        color: #606266;
      }
      li:hover {
        background-color: #edf6ff;
        cursor: pointer;
        color: #606266;
      }
    }
    .right-menu2 {
      z-index: 999;
      position: absolute;
      width: 140px;
      position: absolute;
      border-radius: 5px;
      border: 1px solid #ccc;
      background-color: white;
      .menu-item {
        line-height: 30px;
        text-align: left;
        padding-left: 10px;
        font-size: 14px;
        color: #606266;
      }
      li:hover {
        background-color: #edf6ff;
        cursor: pointer;
        color: #606266;
      }
    }
  }
  .organization_configuration {
    .el-tree--highlight-current
      .el-tree-node.is-current
      > .el-tree-node__content {
      // 设置颜色
      background-color: rgba(
        135,
        206,
        235,
        0.2
      ); // 透明度为0.2的skyblue，作者比较喜欢的颜色
      color: #409eff; // 节点的字体颜色
      font-weight: bold; // 字体加粗
    }
  }
}
</style>
